.brand-first-part {
    font-size: 40px;
    font-family: 'Big Shoulders Stencil Display' !important;
    font-weight: 400;
    line-height: 30px;
    color: #000 !important;
}

.brand-second-part {
    font-size: 40px;
    font-family: 'Big Shoulders Stencil Display' !important;
    font-weight: 400;
    line-height: 30px;
    color: #000;

}

.navbar-brand {
    padding: 20px;
}

.navbar {
    padding-left: 10px;
    padding-right: 30px;
    padding-bottom: 0;

}

.nav-item {
    color: #000 !important;
    font-size: 20px;
    font-family: 'Big Shoulders Stencil Display';
    font-weight: 500;
    line-height: 18px;
}

.navbar-toggler {
    background-color: #fff !important;
    border: 1px solid black;
    width: 30px;
    height: 25px;
    padding: 0px;
}

.dropdown-item-title {
    color: #979797;
    font-family: 'Big Shoulders Stencil Display' !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
}

.dropdown-item-list {
    color: #010101;
    font-family: 'Big Shoulders Stencil Display' !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.nav-link {
    color: #000;

}

.nav-link:focus {
    color: #979797;
}

.nav-link:hover {
    color: #979797;
}

.dropdown-toggle:focus {
    color: #010101;
    background-color: rgba(255, 255, 255, 0.9);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.dropdown-menu {
    border-top-right-radius: 0;
    border-color: rgba(255, 255, 255, 0.9);
    border-top-left-radius: 5px;
}

.dropdown-toggle {
    border: none;
}

@media only screen and (max-width: 600px) {
    .dropdown-menu {
        border-top-left-radius: 0;
    }

    .navbar-brand {
        font-size: 30px;

    }

    .brand-second-part {
        font-size: 30px;
    }

    .navbar {
        padding-right: 10px;


    }

    .nav-item {
        font-size: 10px;
    }

    .navbar-nav {
        padding-left: 0;
    }

    .nav-link {
        font-size: 14px;
        color: #fff;
        background-color: #979797;
        border: solid 1px #fff;
        opacity: 0.5;
    }

    .nav-link:focus {
        color: #000;
    }


}

@media (min-width:600px) and (max-width:1000px) {
    .dropdown-menu {
        border-top-left-radius: 0;
    }

    .navbar-brand {
        font-size: 30px;
        padding: 0;
    }

    .brand-second-part {
        font-size: 30px;
    }

    .navbar {
        padding-right: 10px;
    }

    .nav-item {
        font-size: 20px;
    }

    .navbar-nav {
        padding-left: 0;
    }

    .nav-link {
        font-size: 18px;
        color: #fff;
        background-color: #979797;
        border: solid 1px #fff;
        opacity: 0.5;
    }

    .nav-link:focus {
        color: #000;
    }

    .navbar-toggler {
        background-color: #fff !important;
        border: 1px solid black;
        width: 40px;
        height: 25px;
        padding: 0px;
    }

    .fa {
        width: 30px;
    }

}