.about-us-title {
    color: #000;
    font-family: 'IM Fell French Canon SC';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 59px;
    margin-top: 100px;
    text-align: center;
}

.about-us-first-p {
    color: #000;
    font-family: 'IM Fell French Canon SC';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 230px;
    letter-spacing: 5px;
}

.bg-about-us {
    width: 100vw;
    height: 80vh;
    background-color: #d9d9d9;

}

.black-part {
    color: #fdfdfd;
}

.black-part:hover {
    color: grey;
    text-decoration: none;
}

@media only screen and (max-width: 600px) {

    .about-us-first-p {
        font-size: 14px;
        text-align: justify;
        margin-top: 20px;
        margin-bottom: 0;
        letter-spacing: 0;
        padding-left: 30px;
        padding-right: 30px;
    }

    .bg-about-us {
        width: 100vw;
        height: 80vh;
    }

    .about-us-title {
        font-size: 20px;
        margin-bottom: 0px;
        margin-top: 40px;
        padding-left: 30px;
        padding-right: 30px;

    }
}

@media (min-width:600px) and (max-width:1000px) {

    .about-us-first-p {
        font-size: 18px;
        line-height: 20px;
        padding-left: 50px;
        padding-right: 50px;
        text-align: justify;
    }

    .about-us-title {
        font-size: 25px;
        margin-bottom: 40px;
        margin-top: 40px;
        padding-left: 50px;
        padding-right: 50px;
    }

}