.my-first-div {
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
}


.zero {
    padding-left: 0px;
    padding-right: 0px;
}


.carousel-control-next-icon {
    height: 100px;
    width: 30px;
}

.carousel-control-prev-icon {
    height: 100px;
    width: 30px;
}

@media only screen and (max-width: 600px) {}

@media (min-width:600px) and (max-width:1000px) {}